// Pinia Store
import { computed, inject, ref, shallowRef } from 'vue';
import type { RouteLocationNormalized, Router } from 'vue-router';
import type { AxiosStatic } from 'axios';
import { defineStore } from 'pinia';

import { useAuthStore } from '@/stores/auth';

import { useAccountStore } from '@/stores/account';

import { useSocketStore } from '@/stores/socket';
import type { Nugget } from '@/views/NuggetsView.vue';

const appName = import.meta.env.VITE_APP_NAME;
const isAppImp = appName === 'imp' || appName === 'zly'; // 2022-04-13: added zly here to help with the creation of zly
const isAppJgo = appName === 'jgo';
const isAppTly = appName === 'tly' || appName === 'zae'; // TODO: remove zae
const isAppZly = appName === 'zly';
const isAppZae = appName === 'zae';

console.log('Pinia Global store is being created.'); // no access to Vue.prototype.$log here yet

// Feature detect + local reference
let storage: Storage | null;
let fail;
let uid;
try {
  uid = new Date().toString();
  (storage = window.localStorage).setItem(uid, uid);
  fail = storage.getItem(uid) !== uid.toString();
  storage.removeItem(uid);
  if (fail) {
    storage = null;
  }
} catch (exception) {
  const exceptionName = exception && typeof exception === 'object' && 'name' in exception && exception.name;
  console.log(`Could not access the local storage: ${exceptionName}`);
}

let router: Router;
let alarmsTimeout: number;

export interface Theme {
  id: string;
  name: string;
}

const AVAILABLE_THEMES: Theme[] = [
  { id: 'v2', name: 'v2, 2019' },
  { id: 'v3e', name: 'v3 e, 2020' },
];

const DEFAULT_THEME_INDEX = 1; // v3e

function getThemeFromEnv() {
  let envThemeId = AVAILABLE_THEMES[DEFAULT_THEME_INDEX].id; // the default, unless overridden by an env var
  if (window.ENV && window.ENV.THEME) {
    // set when starting the container, reading from AWS task definition environment variables
    envThemeId = window.ENV.THEME.toLowerCase();
  } else if (import.meta.env.VITE_APP_THEME) {
    // Not found on window.ENV, let's try the process
    envThemeId = import.meta.env.VITE_APP_THEME.toLowerCase();
  }
  return AVAILABLE_THEMES.find((theme) => theme.id === envThemeId) || AVAILABLE_THEMES[DEFAULT_THEME_INDEX];
}

// function injectApp(appParam: App) {
//   app = appParam;
//   $log.debug('global: injectApp OK');
// }

// function appComponentMounted() {
//   // checkVersionChangedSinceLastTime();
//   // checkAlarms();
// }

function injectRouter(routerParam: Router) {
  router = routerParam;
}

export const useGlobalStore = defineStore('global', () => {
  // const router = useRouter(); // not working, will be injected via action
  const $log: any = inject('$log');
  const $http: undefined | AxiosStatic = inject('$http');

  const globalLoadingCounter = ref(0);
  const currentVersion = ref<null | string>(null);
  const currentApiVersion = ref<null | string>(null);
  const newVersionAvailable = ref<null | string>(null);
  const showWhatsNew = ref(false);
  const individualMotivation = ref('');
  const receivedHelpRequestsCount = ref(0);
  const receivedNuggetsCount = ref(0);
  const nuggetsHighlights = ref<Nugget[]>([]);
  const availableThemes = ref<Theme[]>(AVAILABLE_THEMES);
  const selectedTheme = ref<Theme>(getThemeFromEnv());
  const talentManualElementInteractionSwitches = ref({
    showAllQualityCheckMarkers: false,
    showPersonQualityCheck: false,
    showCrossedWords: true,
  });
  // previousRoute: using ref with RouteLocationNormalized causes this two errors:
  //   Vue: The inferred type of useGlobalStore cannot be named without a reference to vue/node_modules/@vue/shared. This is likely not portable. A type annotation is necessary.
  //   Vue: The inferred type of this node exceeds the maximum length the compiler will serialize. An explicit type annotation is needed.
  // using shallowRef "fixes" it.
  const previousRoute = shallowRef<null | RouteLocationNormalized>(null);
  const forceHideDebugButtons = ref(true);
  const events = ref({
    headerAnimateMenuToTeamLeaderInvitations: false,
  });

  // const isDevelopmentEnvironment = computed(() => {
  //   const isLocalDev = process.env.NODE_ENV === 'development';
  //   const hasDevEnv = window.ENV && window.ENV.ENVIRONMENT && window.ENV.ENVIRONMENT.toUpperCase() === 'DEV';
  //   return isLocalDev || hasDevEnv;
  // });
  const isProduction = computed(() => {
    const isLocalDev = process.env.NODE_ENV === 'development';
    if (isLocalDev) {
      return false;
    }
    const accountStore = useAccountStore();
    if (isAppJgo && !accountStore.userHasAdministratorRole) {
      if (window.ENV && window.ENV.ENVIRONMENT && ['DEV', 'DEVELOPMENT'].includes(window.ENV.ENVIRONMENT)) {
        return false;
      }
      return true; // make it "production" even in staging
    }
    // if (isAppTly && !accountStore.userHasAdministratorRole) {
    //   return true; // make it "production" even in staging
    // }
    return !window.ENV || !window.ENV.ENVIRONMENT || window.ENV.ENVIRONMENT.toUpperCase() === 'PRODUCTION';
  });
  const showDebugButtons = computed((): boolean => {
    if (forceHideDebugButtons.value) {
      return false;
    }
    return !isProduction.value && window.ENV && window.ENV.SHOW_DEBUG_BUTTONS === 'true';
  });

  const featureToggle = computed(() => {
    const developmentFeatures = {
      coPilot: true,
      accessControl: true,
      mobileApps: true,
      nuggets: true,
      helpButton: true,
      deleteAccount: true,
      invitationsForTalentManual: true,
      individualEmpowerment: true,
      individualEmpowermentAnalytics: true,
      personalDevelopmentArea: true,
      personalDevelopmentAreaAreas: [
        'PersonalDevelopmentArea__DevelopmentPath',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneMonth',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneQuarter',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneYear',
        'PersonalDevelopmentArea__SoftSkillsCertificate',
        'PersonalDevelopmentArea__SoftSkillsCertificate__Introduction',
        'PersonalDevelopmentArea__SoftSkillsCertificate__TeamMemberCertificate',
        'PersonalDevelopmentArea__SoftSkillsCertificate__TeamLeaderCertificate',
      ],
      doersJournal: true,
      topAndLowSoftFactors: true,
      zlyIntentionsAndGoalsArea: true,
    };

    const stagingFeatures = {
      coPilot: true,
      accessControl: true,
      mobileApps: true,
      nuggets: false,
      helpButton: selectedTheme.value?.id !== 'v3e',
      deleteAccount: true,
      invitationsForTalentManual: true,
      individualEmpowerment: true,
      individualEmpowermentAnalytics: true,
      personalDevelopmentArea: true,
      personalDevelopmentAreaAreas: [
        'PersonalDevelopmentArea__DevelopmentPath',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneMonth',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneQuarter',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneYear',
        'PersonalDevelopmentArea__SoftSkillsCertificate',
        'PersonalDevelopmentArea__SoftSkillsCertificate__Introduction',
        'PersonalDevelopmentArea__SoftSkillsCertificate__TeamMemberCertificate',
        'PersonalDevelopmentArea__SoftSkillsCertificate__TeamLeaderCertificate',
      ],
      doersJournal: true,
      topAndLowSoftFactors: isAppImp || isAppTly || isAppZly || isAppZae,
      zlyIntentionsAndGoalsArea: true,
    };

    const productionFeatures = {
      coPilot: true,
      accessControl: true,
      mobileApps: true,
      nuggets: false,
      helpButton: selectedTheme.value?.id !== 'v3e',
      deleteAccount: false,
      invitationsForTalentManual: true,
      individualEmpowerment: true,
      individualEmpowermentAnalytics: false,
      personalDevelopmentArea: true,
      personalDevelopmentAreaAreas: [
        'PersonalDevelopmentArea__DevelopmentPath',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneMonth',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneQuarter',
        'PersonalDevelopmentArea__DevelopmentPath__OverviewOneYear',
        'PersonalDevelopmentArea__SoftSkillsCertificate',
        'PersonalDevelopmentArea__SoftSkillsCertificate__Introduction',
        // 'PersonalDevelopmentArea__SoftSkillsCertificate__TeamMemberCertificate',
        // 'PersonalDevelopmentArea__SoftSkillsCertificate__TeamLeaderCertificate',
      ],
      doersJournal: isAppImp,
      topAndLowSoftFactors: isAppImp || isAppTly || isAppZly || isAppZae,
      zlyIntentionsAndGoalsArea: false,
    };

    if (window.ENV && window.ENV.ENVIRONMENT) {
      switch (window.ENV.ENVIRONMENT.toUpperCase()) {
        case 'DEV':
        case 'DEVELOPMENT':
          return developmentFeatures;
        case 'STAGING':
          return stagingFeatures;
        case 'PRODUCTION':
        default:
          return productionFeatures;
      }
    }
    return productionFeatures; // default
  });
  const selectedThemeId = computed((): string => selectedTheme.value.id);
  const isThemeV3 = computed((): boolean => selectedThemeId.value === 'v3e');
  const isNotThemeV3 = computed((): boolean => selectedThemeId.value !== 'v3e');
  const isOldThemeV2 = computed((): boolean => selectedThemeId.value === 'v2');
  const elementsColor = computed(() => {
    // if (globalStore.selectedThemeId === 'v1') {
    //   return {
    //     fire: '#A01A1A',
    //     air: '#f6bf08',
    //     water: '#4FA096',
    //     earth: '#203362',
    //   };
    // }
    if (isOldThemeV2.value) {
      return {
        fire: '#d40d12',
        air: '#fcc814',
        water: '#28b09c',
        earth: '#033980',
      };
    }
    // default (v3e)
    return {
      fire: '#d9151d',
      air: '#fccf20',
      water: '#38baa8',
      earth: '#054a8f',
    };
  });

  function resetState() {
    globalLoadingCounter.value = 0;
    currentVersion.value = null;
    currentApiVersion.value = null;
    newVersionAvailable.value = null;
    showWhatsNew.value = false;
    individualMotivation.value = '';
    receivedHelpRequestsCount.value = 0;
    receivedNuggetsCount.value = 0;
    nuggetsHighlights.value = [];
    availableThemes.value = AVAILABLE_THEMES;
    selectedTheme.value = getThemeFromEnv();
    talentManualElementInteractionSwitches.value = {
      showAllQualityCheckMarkers: false,
      showPersonQualityCheck: false,
      showCrossedWords: true,
    };
    previousRoute.value = null;
    forceHideDebugButtons.value = true;
    events.value = {
      headerAnimateMenuToTeamLeaderInvitations: false,
    };
  }

  function increaseGlobalLoadingCounter() {
    globalLoadingCounter.value += 1;

    // reset in case we forget to decrease it
    const timeoutSecs = 5;
    setTimeout(() => {
      // 5 seconds went by
      if (globalLoadingCounter.value > 0) {
        $log.warn(`global: increaseGlobalLoadingCounter: globalLoadingCounter is still > 0 (${globalLoadingCounter.value}) after ${timeoutSecs}s, resetting!`);
        globalLoadingCounter.value = 0;
      }
    }, timeoutSecs * 1000);
  }
  function decreaseGlobalLoadingCounter() {
    if (globalLoadingCounter.value === 0) {
      $log.error('global: decreaseGlobalLoadingCounter: globalLoadingCounter is already zero!');
      return;
    }
    globalLoadingCounter.value -= 1;
  }

  function setCurrentVersion({ version, showChanges }: { version: string; showChanges: boolean }) {
    const previousVersion = currentVersion.value;
    if (!previousVersion) {
      currentVersion.value = version;
      // No previous version; let's check the local storage
      if (storage) {
        const storagePreviousVersion = storage.getItem('current-version');
        if (storagePreviousVersion && storagePreviousVersion !== version) {
          // Different version, show "What’s new?"?
          if (showChanges) {
            showWhatsNew.value = true;
          }
        }
      }
    } else if (previousVersion !== version) {
      // Existing previous version and different: show "New version available"
      newVersionAvailable.value = version;
    }

    if (storage) {
      // Save to local storage so we can check it after the page reloads or at another time
      storage.setItem('current-version', version);
      if (previousVersion && previousVersion !== version) {
        // Set this so that after page refresh or login we can show "What's new?"
        storage.setItem('show-whats-new', showChanges.toString());
      }
    }
  }

  function setCurrentApiVersion({ version }: { version: string }) {
    currentApiVersion.value = version;
  }

  // function resetShowWhatsNew() {
  //   showWhatsNew.value = false;
  // }

  function checkVersionChangedSinceLastTime() {
    const authStore = useAuthStore();
    if (!authStore.isAuthenticated) {
      return;
    }
    if (storage) {
      const showWhatsNewFromStorage = storage.getItem('show-whats-new');
      if (showWhatsNewFromStorage && showWhatsNewFromStorage !== 'false') {
        storage.removeItem('show-whats-new');
        showWhatsNew.value = true;
      }
    }
  }

  function setReceivedHelpRequestsCount(count: number) {
    receivedHelpRequestsCount.value = count;
  }

  async function checkAlarms() {
    if (appName !== 'imp') {
      return;
    }
    const authStore = useAuthStore();
    if (!authStore.isAuthenticated) {
      return;
    }

    // const randomString = [...Array.from({ length: 5 })].map(() => (~~(Math.random() * 36)).toString(36)).join('');
    let connectedToSocket = false;
    try {
      // If connected to the socket, do not poll.
      const socketStore = useSocketStore();
      connectedToSocket = socketStore.connectedToHelpRequests;
      if (connectedToSocket) {
        // Joined.
        $log.debug('checkAlarms: already connected to the socket "requests" channel: not polling.');
      } else {
        $log.debug('checkAlarms: not connected to the socket "requests" channel: polling.');
        // {help: {total: <int>}, nuggets: {total: <int>}}
        // $log.info(`Debug 20190701 | ${randomString}: will request /session/alarms`);
        interface AlarmsResponse {
          data: {
            help: {
              total: number;
            };
            nuggets: {
              total: number;
              list: Nugget[];
            };
          };
        }
        // Do not poll if using avatar account (prevent page refresh when the token expires after ~5 minutes)
        const accountStore = useAccountStore();
        if (accountStore.profile?.is_avatar) {
          return;
        }
        const response: undefined | AlarmsResponse = await $http?.get('/session/alarms', { signal: undefined });
        if (!response) {
          return;
        }
        // $log.info(`Debug 20190701 | ${randomString}: success requesting /session/alarms`);
        if ('help' in response.data && 'total' in response.data.help) {
          // $log.info(`Debug 20190701 | ${randomString}: help in response.data && total in response.data.help / before SET_RECEIVED_HELP_REQUESTS_COUNT`);
          setReceivedHelpRequestsCount(response.data.help.total);
          // $log.info(`Debug 20190701 | ${randomString}: after SET_RECEIVED_HELP_REQUESTS_COUNT`);
        }
        if ('nuggets' in response.data && 'total' in response.data.nuggets) {
          // $log.info(`Debug 20190701 | ${randomString}: nuggets in response.data && total in response.data.nuggets / before SET_RECEIVED_NUGGETS_COUNT`);
          nuggetsHighlights.value = [];
          receivedNuggetsCount.value = response.data.nuggets.total;
          // $log.info(`Debug 20190701 | ${randomString}: after SET_RECEIVED_NUGGETS_COUNT`);
          // Check nuggets component highlighting
          if ('list' in response.data.nuggets) {
            // $log.info(`Debug 20190701 | ${randomString}: list in response.data.nuggets, will forEach to check the nugget component for the highlights`);
            response.data.nuggets.list.forEach((nugget) => {
              if (nugget.component && !('postponed_to' in nugget && nugget.postponed_to)) { // TODO check if postponed_to exists in nugget
                nuggetsHighlights.value.push(nugget);
              }
            });
          }
        }
      }
    } catch (error) {
      // $log.info(`Debug 20190701 | ${randomString}: error requesting /session/alarms`);
      // $log.info(`Debug 20190701 | ${randomString}: checkAlarms(): error fetching:`, error);
      $log.info('checkAlarms(): error fetching:', error);
    } finally {
      if (!connectedToSocket) {
        // schedule a repeat (clear existing)
        const getRandomInteger = (min: number, max: number) => Math.floor(Math.random() * (max - min + 1)) + min;
        const nextRetryMs = getRandomInteger(30, 60) * 1000; // retry in 30 to 60 seconds
        clearTimeout(alarmsTimeout);
        alarmsTimeout = window.setTimeout(() => {
          checkAlarms();
        }, nextRetryMs);
      }
    }
  }

  function userSignedIn() {
    // called from auth store
    checkVersionChangedSinceLastTime();
    setTimeout(() => {
      // Some time for the Socket to connect
      checkAlarms();
    }, 3000);
  }

  async function performActionUponNotification(payload: unknown) {
    if (typeof payload === 'object'
      && payload && 'data' in payload
      && typeof payload.data === 'object'
      && payload.data
      && 'category' in payload.data
      && payload.data.category === 'impulze'
      && 'role' in payload.data) {
      $log.debug('performActionUponNotification: will call changeRoleIDServerSide.');
      const accountStore = useAccountStore();
      await accountStore.changeRoleIDServerSide(payload.data.role as number);
      $log.debug('performActionUponNotification: awaited for changeRoleIDServerSide, will change route.');
      await router.push({ name: 'ImpulseArea' });
    }
  }

  function selectTheme(payload: string) {
    const foundTheme = availableThemes.value.find((theme) => theme.id === payload);
    if (foundTheme) {
      selectedTheme.value = foundTheme;
    } else {
      $log.warn('selectTheme: theme not found:', payload, availableThemes.value);
    }
  }

  function setTalentManualElementInteractionSwitchesShowAllQualityCheckMarkers(payload: boolean) {
    talentManualElementInteractionSwitches.value.showAllQualityCheckMarkers = payload;
  }

  function setTalentManualElementInteractionSwitchesShowPersonQualityCheck(payload: boolean) {
    talentManualElementInteractionSwitches.value.showPersonQualityCheck = payload;
  }

  function setTalentManualElementInteractionSwitchesShowCrossedWords(payload: boolean) {
    talentManualElementInteractionSwitches.value.showCrossedWords = payload;
  }

  function setPreviousRoute(from: RouteLocationNormalized) {
    previousRoute.value = from;
  }

  function toggleForceHideDebugButtons() {
    forceHideDebugButtons.value = !forceHideDebugButtons.value;
  }

  function setEventHeaderAnimateMenuToTeamLeaderInvitations(payload: boolean) {
    events.value.headerAnimateMenuToTeamLeaderInvitations = payload;
  }

  return {
    appName,
    isAppImp,
    isAppJgo,
    isAppTly,
    isAppZly,
    isAppZae,
    //
    // State
    //
    globalLoadingCounter,
    currentVersion,
    currentApiVersion,
    newVersionAvailable,
    showWhatsNew,
    individualMotivation,
    receivedHelpRequestsCount,
    receivedNuggetsCount,
    nuggetsHighlights,
    availableThemes,
    selectedTheme,
    talentManualElementInteractionSwitches,
    previousRoute,
    forceHideDebugButtons,
    events,
    //
    // Getters
    //
    // isDevelopmentEnvironment,
    isProduction,
    showDebugButtons,
    featureToggle,
    selectedThemeId,
    isThemeV3,
    isNotThemeV3,
    isOldThemeV2,
    elementsColor,
    //
    // Actions
    //
    injectRouter,
    resetState,
    // getThemeFromEnv,
    increaseGlobalLoadingCounter,
    decreaseGlobalLoadingCounter,
    setCurrentVersion,
    setCurrentApiVersion,
    // resetShowWhatsNew,
    // appComponentMounted,
    setReceivedHelpRequestsCount,
    checkAlarms,
    userSignedIn,
    performActionUponNotification,
    selectTheme,
    setTalentManualElementInteractionSwitchesShowAllQualityCheckMarkers,
    setTalentManualElementInteractionSwitchesShowPersonQualityCheck,
    setTalentManualElementInteractionSwitchesShowCrossedWords,
    setPreviousRoute,
    toggleForceHideDebugButtons,
    setEventHeaderAnimateMenuToTeamLeaderInvitations,
  };
});
